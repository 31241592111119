/** @jsx jsx */
import React from "react"
import { jsx, Styled } from "theme-ui"

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import ItemTags from "@lekoarts/gatsby-theme-minimal-blog/src/components/item-tags"
import SEO from "@lekoarts/gatsby-theme-minimal-blog/src/components/seo"
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config"

type PostProps = {
  data: {
    post: {
      slug: string
      title: string
      date: string
      tags?: {
        name: string
        slug: string
      }[]
      description?: string
      body: string
      excerpt: string
      timeToRead: number
      banner?: {
        childImageSharp: {
          resize: {
            src: string
          }
        }
      }
    }
  }
}

const px = [`32px`, `16px`, `8px`, `4px`]

const Post = ({ data: { post } }: PostProps) => {
  const { basePath, blogPath } = useMinimalBlogConfig()
  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.description ? post.description : post.excerpt}
        image={post.banner ? post.banner.childImageSharp.resize.src : undefined}
        pathname={post.slug}
      />
      <Styled.h2>{post.title}</Styled.h2>
      <p sx={{ color: `secondary`, mt: 2, a: { color: `primary` }, fontSize: [1, 1, 1] }}>
        <time>{post.date}</time>
        {post.tags && (
          <React.Fragment>
            {` — `}
            <ItemTags tags={post.tags} />
          </React.Fragment>
        )}
        {` — `}
        <span>{post.timeToRead} min read</span>
      </p>
      <section sx={{ my: 4 }}>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr sx={{ backgroundColor: `muted`, border: 0, height: `1px` }}/>
      </section>
      <section
        ref={elem => {
          if (!elem) {
            return;
          }
          const scriptElem = document.createElement(`script`);
          scriptElem.src = `https://utteranc.es/client.js`;
          scriptElem.async = true;
          scriptElem.crossOrigin = `anonymous`;
          scriptElem.setAttribute(`repo`, `hippothewild/comments.jchun.dev`);
          scriptElem.setAttribute(`issue-term`, `pathname`);
          scriptElem.setAttribute(`theme`, `github-light`);
          elem.appendChild(scriptElem);
        }}
      />
    </Layout>
  )
}

export default Post
